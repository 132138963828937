import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class CtaService {
  list(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/call_to_actions?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/call_to_actions?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  get(id) {
    return axios.get(API_URL + `admin/call_to_actions/${id}`, { headers: authHeader() });
  }

  save(data) {
    return axios.post(API_URL + `admin/call_to_actions/${data.id}`, { ...data }, { headers: authHeader() });
  }

  async create(formdata) {
    const { data } = await axios.post(API_URL + `admin/call_to_actions/`, formdata, { headers: authHeader() });
    return data;
  }

  delete(id) {
    return axios.delete(API_URL + `admin/call_to_actions/${id}`, { headers: authHeader() });
  }
}

export default new CtaService();
