<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-6">
            <label for="first_name" class="block text-sm font-medium text-gray-700">Headline</label>
            <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="button_text" class="block text-sm font-medium text-gray-700">Button text (niet te lang!) 1 of 2 woorden</label>
            <input type="text" name="button_text" id="button_text" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.button_text">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="button_url" class="block text-sm font-medium text-gray-700">Button url</label>
            <input type="text" name="button_url" id="button_url" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.button_url">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="company_website" class="block text-sm font-medium text-gray-700">
              New window?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.link_new_window ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="toggleNewWindow">
                <span class="sr-only">Open link in new window?</span>
                <span aria-hidden="true" :class="c.link_new_window ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="email_address" class="block text-sm font-medium text-gray-700">Description</label>
            <TinyMceEditor v-model="c.description" model_name="CallToAction" :model_id="c.id" :image="false" />
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import CtaService from '../../../services/admin/call_to_actions/service';
import TinyMceEditor from '../generic/TinyMceEditor.vue';

export default {
  name: 'CtaService',
  props: ['call_to_action'],
  components: {
    TinyMceEditor
  },
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.call_to_action,
      loading
    }
  },
  methods: {
    toggleNewWindow() {
      this.c.link_new_window = !this.c.link_new_window;
    },
    async handleSave() {
      this.loading = true;
      CtaService.save(this.c);
      this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'The call to action has been saved succesfully'});
      this.loading = false;
    }
  }
}
</script>
